'use client';

import { captureException } from '@mentimeter/errors/sentry';
import { useEffect } from 'react';
import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Heading } from '@mentimeter/ragnar-ui/typography';
import { Button } from '@mentimeter/ragnar-ui/button';
import Logo from '../Logo';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    captureException(error);
  }, [error]);

  return (
    <Box
      minHeight="100vh"
      flexDirection="column"
      alignItems="stretch"
      justifyContent="stretch"
    >
      <Box
        flex="1"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="stretch"
      >
        <Box
          flex="1"
          alignItems="center"
          justifyContent="center"
          py={4}
          px={3}
          bg="#E7E8EB"
        >
          <Logo />
          <Box
            width="90%"
            maxWidth={480}
            alignItems="center"
            borderRadius={4}
            bg="#FFF"
            p={32}
          >
            <Heading as="h1">Something went wrong...</Heading>
            <Button
              variant="primary"
              width={1}
              mt={3}
              aria-label="Reload page"
              onClick={
                // Attempt to recover by trying to re-render the segment
                () => reset()
              }
            >
              Try Again
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
